import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Button, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {
  firestoreConnect,
  withFirebase,
  withFirestore,
  isLoaded
} from 'react-redux-firebase';
import WithLoader from 'components/WithLoader';
import * as collections from 'constants/collections';
import { actions as recipientsActions } from 'reducers/recipient';
import { actions as fetchingActions } from 'reducers/fetching';
import { actions as destinationActions } from 'reducers/destination';
import { actions as metrcLicenseActions } from 'reducers/mraLicense';
import 'react-phone-number-input/style.css';
import CustomerList from './CustomerList';
import CustomerForm from './CustomerForm';
import DestinationTableList from './DestinationTableList';
import SearchBar from './SearchBar';
import { CUSTOMER } from 'constants/roles';
import { COMPANY, CUSTOMER_CREATED, CUSTOMER_UPDATED } from 'constants/customers';
import { getCustomersWithRecipients } from './filters';

class Customers extends Component {
  constructor(props) {
    super(props);
    this.showRecipient = this.showRecipient.bind(this);
    this.showList = this.showList.bind(this);
    this.updateCustomerList = this.updateCustomerList.bind(this);

    const { recipient, destination } = this.props;

    this.state = {
      activeDiv: "list",
      recipient: recipient,
      destination: destination,
      result: null,
      destinationForErrors: false,
      filterText: '',
      customerRecipients: [],
      [CUSTOMER_CREATED]: false,
      [CUSTOMER_UPDATED]: false,
    };
  }

  reloadCustomerRecipients(resetNewCustomerFlag = false) {
    const { recipients, users } = this.props;
    const data = getCustomersWithRecipients(users, recipients, this.state.filterText);
    this.setState({
      customerRecipients: data,
      [CUSTOMER_UPDATED]: false
    });
    if (resetNewCustomerFlag) {
      this.setState({ [CUSTOMER_CREATED]: false });
    }
  }

  componentDidMount() {
    this.reloadCustomerRecipients();
  }

  componentDidUpdate(prevProps, prevState) {
    const { users } = this.props;
    if (this.state[CUSTOMER_UPDATED] || this.state.filterText !== prevState.filterText) {
      this.reloadCustomerRecipients();
    }
    if (this.state[CUSTOMER_CREATED] && users.length !== prevProps.users.length) {
      this.reloadCustomerRecipients(true);
    }
  }

  handleChange(event) {
    const { firestore } = this.props;

    if (
      window.confirm(
        "Please confirm to " +
        (event.target.checked ? "enable" : "disable") +
        " this item?"
      )
    ) {
      firestore
        .collection(collections.DESTINATION)
        .doc(event.target.id)
        .update({
          active: event.target.checked
        })
        .then(result => { });
    } else {
      event.target.checked = !event.target.checked;
    }
  }

  showList() {
    this.setState({ activeDiv: 'list' });
    // This will clear the recipient form
    this.props.clear();
  }

  updateCustomerList(key = CUSTOMER_UPDATED) {
    this.setState({ [key]: true });
  }

  static propTypes = {
    role: PropTypes.string,
    className: PropTypes.string
  };

  showRecipient = recipient => {
    const { onRecipientEdit, onRecipientNew } = this.props;
    if (!!recipient) {
      onRecipientEdit(recipient);
    } else {
      onRecipientNew();
    }

    setTimeout(function () {
      this.setState({
        activeDiv: 'form', destinationForErrors: false
      });
    }.bind(this), 200)
  };

  onUpdateFilterText = (filterText) => {
    this.setState({ filterText: filterText })
  }

  render() {
    const { recipient } = this.props;

    if (isLoaded(this.state.customerRecipients)) {
      return (
        <div>
          <div>
            {this.state.activeDiv !== "form" ? (
              <>
                <Row className="row-cols-lg-auto g-3 align-items-center">
                  <Col>
                    <Button color="primary" onClick={() => this.showRecipient()}>
                      New Customer
                    </Button>
                  </Col>
                  <Col>
                    <SearchBar onUpdateFilterText={this.onUpdateFilterText} />
                  </Col>
                </Row>
                <CustomerList
                  showRecipient={this.showRecipient}
                  showList={this.showList}
                  customerRecipients={this.state.customerRecipients}
                  updateCustomerList={this.updateCustomerList}
                  {...this.props}
                />
              </>
            ) : (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => this.showList()}
                >
                  Back
                </Button>
                <CustomerForm
                  {...this.props}
                  showList={this.showList}
                  handleNotificationChange={this.handleNotificationChange}
                  handleNotificationSmsAndEmailChange={this.handleNotificationSmsAndEmailChange}
                  updateCustomerList={this.updateCustomerList}
                />
              </>
            )}
          </div>
          {
            (recipient.id != null && this.state.activeDiv === "form")
              && <DestinationTableList {...this.props} handleChange={this.handleChange} />
           }
        </div>
      );
    }
    return "";
  }
}

const mapStateToProps = ({
  mraLicense,
  recipient,
  destination,
  firestore: {
    ordered: { recipients = [], settings, destinations = [], mraLicenses }
  }
}, {users = []}) => ({
  mraLicense,
  recipient,
  destination,
  destinations: destinations?.filter(item => item.owner === recipient.id && !item.disabled),
  settings: settings && settings.length && settings[0],
  mraLicenses: (mraLicenses && mraLicenses.filter(item => item.owner === recipient.id)) || [],
  recipients: recipients?.filter(recipient => recipient.type === COMPANY),
  users: users.filter(user => user.role === CUSTOMER)
});

const mapDispatchToProps = {
  ...recipientsActions,
  ...fetchingActions,
  ...destinationActions,
  ...metrcLicenseActions
};

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  firestoreConnect([
    {
      collection: collections.DESTINATION
    },
    {
      collection: collections.MRA_LICENSE
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Customers);

export default WithLoader(FirestoreConnected);
