const firebase = {
  apiKey: 'AIzaSyCQRFqJ-_FAer5NpybPhMaE3X7UdkkmkWQ',
  authDomain: 'boltbox-admin.firebaseapp.com',
  databaseURL: 'https://boltbox-admin.firebaseio.com',
  projectId: 'boltbox-admin',
  storageBucket: 'boltbox-admin.appspot.com',
  messagingSenderId: '999650919261'
};

const google = {
  analytics: {
    trackingId: '',
  },
  recaptcha: {
    siteKey: '6LepjvwUAAAAAP6jYnzmkAv4h4OVQeZKQuOMf5Vw',
  }
};

const sentry = {
  dsn: 'https://aa79810340034a4db793816541601491@sentry.io/1399089',
  release: 'boltbox-admin@644063f07b0cdf5c53f4fe6384e02a0b3f357279'
};

const domain = 'boltbox-admin.firebase.com';
const site = {
  domain,
  title: 'BoltBox App Admin',
  url: `https://${domain}`,
  development: false,
};

const mapbox = {
  accessToken: 'pk.eyJ1IjoiYm9sdGJveGFwcCIsImEiOiJja2FqNWZzZngwOGMzMnJuc2Y5cTR2MGh0In0.DrSE_Ml-gNE-Swe6b6YYXQ',
  navigationApi: 'https://api.mapbox.com/directions/v5/mapbox/driving/{location}?access_token={accessToken}',
  showInstructions: true,
};

// Our own apis
const api = {
  searchPlaces: 'https://us-central1-boltbox-admin.cloudfunctions.net/searchPlace',
  geocode: 'https://us-central1-boltbox-admin.cloudfunctions.net/geocode',
  sendSMS: 'https://us-central1-boltbox-admin.cloudfunctions.net/sendSMS',
  sendEmail: 'https://us-central1-boltbox-admin.cloudfunctions.net/sendEmail',
  createPDFInvoice: 'https://us-central1-boltbox-admin.cloudfunctions.net/createPdfInvoice',
  checkRecaptcha: 'https://us-central1-boltbox-admin.cloudfunctions.net/checkRecaptcha',
  getOrders: 'https://us-central1-boltbox-admin.cloudfunctions.net/getOrders',
  getOrderReportUrl: 'https://us-central1-boltbox-admin.cloudfunctions.net/getOrderReportUrl',
  getVehicleRoutesReport: 'https://us-central1-boltbox-admin.cloudfunctions.net/getVehicleRoutes',
};

const login = {
  resetTime: {
    amount: 30,
    unit: 'seconds'
  }
}

const featureFlags = {
  metrcIsEnabled: false,
}

const environment = {
  customerPortalUrl: 'https://cp.jklogix.com/',
  adminPortalUrl: 'https://admin.boltboxapp.com/',
};

export {
  api,
  firebase,
  google,
  mapbox,
  sentry,
  site,
  login,
  featureFlags,
  environment,
};
