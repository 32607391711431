import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Button, CustomInput, FormGroup, Label, Input, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
  firestoreConnect,
  withFirebase,
  withFirestore
} from 'react-redux-firebase';
import WithLoader from 'components/WithLoader';
import * as collections from 'constants/collections';
import { actions as recipientsActions } from 'reducers/recipient';
import { actions as fetchingActions } from 'reducers/fetching';
import { actions as destinationActions } from 'reducers/destination';
import { actions as metrcLicenseActions } from 'reducers/mraLicense';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input'
import Select from 'components/Select';
import { now, notificationTypes } from 'utils';
import { NotificationManager } from 'components/Layout';
import { pick } from 'lodash';
import DestinationForm from './DestinationForm';
import { CUSTOMER } from 'constants/roles';
import {
  COMPANY,
  ERROR_EMAIL_ALREADY_REGISTERED_BY_USER,
  ERROR_EMAIL_ALREADY_REGISTERED_BY_COMPANY,
  ERROR_SAVING_COMPANY,
  ERROR_SAVING_CUSTOMER,
  SUCCESS_CUSTOMER_CREATED,
  SUCCESS_CUSTOMER_UPDATED,
  CUSTOMER_UPDATED,
  CUSTOMER_CREATED
} from 'constants/customers';

class CustomerForm extends Component {
  static propTypes = {
    onNameChanged: PropTypes.func,
    onPhoneChanged: PropTypes.func,
    onEmailChanged: PropTypes.func,
    onBillingAddressChanged: PropTypes.func,
    onArrivingNotificationIntervalChanged: PropTypes.func,
    recipient: PropTypes.object,
    destinations: PropTypes.array,
    destination: PropTypes.object,
    settings: PropTypes.any,
    onEnableAccountChanged: PropTypes.func,
    customer: PropTypes.object,
    mraLicense: PropTypes.object,
    onMetrcLicenseNumberChanged: PropTypes.func,
    showList: PropTypes.func,
    clear: PropTypes.func,
    onRecipientNew: PropTypes.func,
    setLoading: PropTypes.func,
    firestore: PropTypes.any,
  };

  isEmailAlreadyInUse = async (email, collection) => {
    const { firestore } = this.props;
    const result = await firestore.collection(collection).where('email', '==', email).get();
    return !result.empty;
  }

  saveRecipientCompanyData = async () => {
    const { recipient, firestore } = this.props;

    try {
      const recipientId = recipient.recipientId;
      const customerId = recipient.id;
      const recipientInfo = pick(recipient, [
        'name',
        'phone',
        'email',
        'billingAddressId',
        'metrcLicenseNumber',
        'smsNotification',
        'emailNotification',
        'notificationTypes',
        'enableCashMovements',
        'isFinancialInstitution',
        'financialInstitutionId',
        'ownerId'
      ]);

      let recipientData = {
        ...recipientInfo,
        arrivingNotificationInterval: recipient.arrivingNotificationInterval ?? 30,
        modifiedAt: now()
      }

      if (!recipientData.enableCashMovements) {
        recipientData = {
          ...recipientData,
          isFinancialInstitution: false,
          financialInstitutionId: null
        }
      }

      // If is a new company (recipient)
      if (!recipientId) {
        const isEmailInUseByRecipient = await this.isEmailAlreadyInUse(recipientData.email, collections.RECIPIENT);
        const isEmailInUseByUser = await this.isEmailAlreadyInUse(recipientData.email, collections.USER);
        // If is a new user and new company and the email is not in use, we can create a new recipient
        if ((!customerId && !isEmailInUseByRecipient && !isEmailInUseByUser) || customerId) {
          const recipientToAdd = {
            ...recipientData,
            name: recipient.companyName,
            email: recipient.email,
            disabled: false,
            type: COMPANY
          };
          const newRecipient = await firestore.add(collections.RECIPIENT, recipientToAdd);
          return {
            ...recipientToAdd,
            id: newRecipient?.id ?? null
          };
        } else {
          const errorMsg = isEmailInUseByRecipient
            ? ERROR_EMAIL_ALREADY_REGISTERED_BY_COMPANY
            : ERROR_EMAIL_ALREADY_REGISTERED_BY_USER;
          NotificationManager.error(errorMsg);
          return false;
        }
      }
      
      return {
        ...recipientData,
        id: recipientId
      };
    } catch {
      NotificationManager.error(ERROR_SAVING_COMPANY);
      return false;
    }
  };

  saveCustomerAccountData = async (recipientId) => {
    const { recipient, firestore } = this.props;
    const customerId = recipient.id;
    const customerData = {
      ...(customerId ? { id: customerId } : {}),
      name: recipient.name,
      email: recipient.email,
      phone: recipient.phone,
      role: CUSTOMER,
      disabled: recipient.disabled,
      recipientId: recipientId,
      id: customerId
    }
    try {
      // If is an existing user
      if (customerData.id) {
        await firestore.collection(collections.USER).doc(customerData.id).update(customerData);
        return {
          ...customerData,
          isNew: false
        };
      } else {
        const result = await firestore.add(collections.NEW_USERS, customerData);
        return {
          ...customerData,
          id: result?.id ?? null,
          isNew: true
        };
      }
    } catch (error) {
      console.log("🚀 ~ CustomerForm ~ saveCustomerAccountData=async ~ error:", error)
      NotificationManager.error(ERROR_SAVING_CUSTOMER);
      return null;
    }
  };

  saveCustomer = async () => {
    const { clear, onRecipientNew, showList, setLoading, updateCustomerList } = this.props;
    try {
      // Show loading
      setLoading(true);
      // First, we try to save the recipient data
      const recipientResult = await this.saveRecipientCompanyData();
      const recipientId = recipientResult.id;
      // If we have recipientId, we have to create or update the customer account data and include the recipientId
      if (recipientId) {
        const customer = await this.saveCustomerAccountData(recipientId);
        // If customerAccountWasSaved is true, means the customer account was created or updated successfully
        if (customer?.id) {
          const successMsg = customer.isNew ? SUCCESS_CUSTOMER_CREATED : SUCCESS_CUSTOMER_UPDATED;
          NotificationManager.success(successMsg);
          const key = customer.isNew ? CUSTOMER_CREATED : CUSTOMER_UPDATED;
          updateCustomerList(key);
          clear();
          showList();
          onRecipientNew();
        } else {
          NotificationManager.error(ERROR_SAVING_CUSTOMER);
        }
      }
    } catch (error) {
      NotificationManager.error(ERROR_SAVING_CUSTOMER);
    } finally {
      setLoading(false);
    }
  };

  handleNotificationChange(checkedValue, name) {
    const { onNotificationTypeChanged } = this.props;
    onNotificationTypeChanged(name, checkedValue)
  }

  handleNotificationSmsAndEmailChange(targetValue, name) {
    const { onSmsNotificationChanged, onEmailNotificationChanged } = this.props;

    if (name === 'smsNotification') {
      onSmsNotificationChanged(targetValue);
    } else {
      onEmailNotificationChanged(targetValue);
    }
  }

  getRecipientsNameOptions = (newOption) => {
    const { companyRecipients } = this.props;
    let options = [];

    if (newOption) {
      options.push(newOption);
    }

    const uniqueCompanies = [...new Map(companyRecipients.map(item => [item.id, item])).values()];
    for (let i in uniqueCompanies) {
      const { id, name } = uniqueCompanies[i];
      options.push({ value: id, label: name });
    }

    return options.sort((a, b) => (a.label > b.label ? 1 : -1));
  };

  getFinancialInstitutionOptions = () => {
    const { companyRecipients } = this.props;
    let options = [];

    const financialInstitutions = companyRecipients.filter(c => c.isFinancialInstitution);
    for (const item of financialInstitutions) {
      options.push({ value: item.id, label: item.name });
    }

    return options;
  };

  handleRecipientChanged(company) {
    const {
      companyRecipients,
      onCompanyNameChanged,
      onCompanyIdChanged,
    } = this.props;
    const selectedRecipient = companyRecipients.find(item => item.id === company);
    if (selectedRecipient) {
      const {
        name: companyName,
        notificationTypes: currentNotificationTypes,
        smsNotification,
        emailNotification
      } = selectedRecipient;
      onCompanyIdChanged(company);
      onCompanyNameChanged(companyName);
      // Set SMS Notifications
      if (smsNotification) {
        this.handleNotificationSmsAndEmailChange(smsNotification, 'smsNotification');
      }
      // Set Email Notifications
      if (emailNotification) {
        this.handleNotificationSmsAndEmailChange(emailNotification, 'emailNotification');
      }
      // Set notification types
      if (currentNotificationTypes) {
        for (let key in currentNotificationTypes) {
          this.handleNotificationChange(currentNotificationTypes[key], key);
        }
      }
    } else {
      this.handleNotificationSmsAndEmailChange(false, 'smsNotification');
      this.handleNotificationSmsAndEmailChange(false, 'emailNotification');
      for (let key in notificationTypes) {
        this.handleNotificationChange(false, key);
      }
      onCompanyIdChanged('');
      onCompanyNameChanged(company);
    }
  }

  render() {
    const {
      onNameChanged,
      onPhoneChanged,
      onEmailChanged,
      onBillingAddressChanged,
      onArrivingNotificationIntervalChanged,
      recipient,
      destinations,
      settings,
      onEnableAccountChanged,
      onEnableCashMovementsChanged,
      onIsFinancialInstitutionChanged,
      onFinancialInstitutionChanged,
      showList,
    } = this.props;

    return (
      <div className="row">
        <div className="col-xs-12 col-md-12">
          <form
            className="p-t-15"
            onSubmit={(e) => {
              e.preventDefault();
              this.saveCustomer();
            }}
          >
            <div className="form-group form-group-default">
              <label>Name</label>
              <div className="controls">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="form-control"
                  required
                  value={recipient.name ?? ""}
                  onChange={(event) => {
                    onNameChanged(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group form-group-default">
              <label>Phone</label>
              <div className="controls">
                <PhoneInput
                  className="form-control"
                  placeholder="Phone Number"
                  defaultCountry="US"
                  type="text"
                  required
                  name="phone"
                  value={recipient.phone ?? ""}
                  onChange={(event) => {
                    onPhoneChanged(event);
                  }}
                />
              </div>
            </div>
            <div className="form-group form-group-default">
              <label>Email</label>
              <div className="controls">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                  required
                  value={recipient.email ?? ""}
                  onChange={(event) => {
                    onEmailChanged(event.target.value);
                  }}
                />
              </div>
            </div>
            <FormGroup
              id="RecipientFormGroup"
              className="form-group-default form-group-default-select2"
            >
              <Label for="recipient">Company Name</Label>
              <Select
                data-test-id="recipient"
                name="recipient"
                onChange={(event) => {
                  this.handleRecipientChanged(event.target.value);
                }}
                creatable
                placeholder={"Select"}
                value={recipient.recipientId ? recipient.recipientId : (recipient.companyName ?? '')}
                onCreate={(params) => {
                  const { term } = params;
                  if (term.trim().length === 0) {
                    return null;
                  }
                  return {
                    id: term.trim(),
                    text: `${term.trim()} (New Company)`,
                  };
                }}
                options={this.getRecipientsNameOptions(false)}
                readOnly={false}
              />
            </FormGroup>
            {/* This works as a select component */}
            {destinations.length > 0 && (
              <div
                className="form-group form-group-default form-group-default-select2"
                id="billing-address-wrapper"
              >
                <label>Billing Address</label>
                <Select
                  name="billingAddress"
                  id="billing-address"
                  value={recipient.billingAddressId ?? ""}
                  options={destinations.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                  onChange={(event) => {
                    onBillingAddressChanged(event.target.value);
                  }}
                />
              </div>
            )}
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group form-group-default">
                  <label>Account</label>
                  <div className="controls">
                    <CustomInput
                      type="checkbox"
                      id="accountEnable"
                      name="accountEnable"
                      label="ENABLE"
                      checked={!recipient.disabled}
                      onChange={(event) => {
                        onEnableAccountChanged(!event.target.checked)
                      }}
                    />
                    {!recipient.recipientId && (
                      <span className='helper-text'>By checking this box, you agree to create an account and email login credentials to the customer</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group form-group-default">
                  <label>Cash Movements</label>
                  <div className="controls">
                    <CustomInput
                      type="checkbox"
                      id="enableCashMovements"
                      name="enableCashMovements"
                      label="Enable"
                      checked={recipient.enableCashMovements}
                      onChange={(event) => {
                        onEnableCashMovementsChanged(event.target.checked)
                      }}
                    />
                  </div>
                  {recipient.enableCashMovements && (
                    <div className="controls">
                      <CustomInput
                        id="isFinancialInstution"
                        type="checkbox"
                        label="Is Financial Institution?"
                        checked={recipient.isFinancialInstitution}
                        onChange={event => { onIsFinancialInstitutionChanged(event.target.checked) }}
                      />
                    </div>
                  )}
                  {recipient.enableCashMovements && (
                    <FormGroup
                      id="FinancialInstitution"
                      className="form-group-default form-group-default-select2 mt-2"
                    >
                      <Label for="recipient">Financial Institution</Label>
                      <Select
                        data-test-id="financialInstitutionId"
                        name="financialInstitutionId"
                        onChange={(e) => {
                          onFinancialInstitutionChanged(e.target.value);
                        }}
                        placeholder="Select"
                        value={recipient.financialInstitutionId ?? null}
                        options={this.getFinancialInstitutionOptions()}
                      />
                    </FormGroup>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group form-group-default">
                  <label>Notifications</label>
                  <div className="controls">
                    <CustomInput
                      type="checkbox"
                      id="smsNotification"
                      name="smsNotification"
                      label="SMS"
                      checked={recipient.smsNotification}
                      onChange={event =>
                        this.handleNotificationSmsAndEmailChange(event.target.checked, "smsNotification")
                      }
                      disabled={recipient.recipientId !== ''}
                    />
                    <CustomInput
                      type="checkbox"
                      id="emailNotification"
                      name="emailNotification"
                      label="EMAIL"
                      checked={recipient.emailNotification}
                      onChange={event =>
                        this.handleNotificationSmsAndEmailChange(event.target.checked, "emailNotification")
                      }
                      disabled={recipient.recipientId !== ''}
                    />
                  </div>
                </div>
                {(recipient.smsNotification || recipient.emailNotification) &&
                  <div className="col-sm-12">
                    <div className="form-group form-group-default">
                      <label>Notification Types</label>
                      <div className="controls">
                        {notificationTypes.map((notification, idx) =>
                          <Row key={`${idx}`}>
                            <CustomInput
                              type="checkbox"
                              label={notification.label}
                              id={`notificationType-${idx}`}
                              checked={recipient.notificationTypes[notification.name] || false}
                              defaultValue={notification.name}
                              onChange={event => { this.handleNotificationChange(event.target.checked, notification.name) }}
                              disabled={recipient.recipientId !== ''}
                            />
                          </Row>)}
                      </div>
                    </div>
                  </div>
                }
              </div>
              {recipient.notificationTypes["on_order_arriving_interval"] &&
                <div className="col-sm-6">
                  <div className="form-group form-group-default">
                    <label>Arriving Notification Interval</label>
                    <div className="controls">
                      <Input
                        type="select"
                        id="arrivingNotificationInterval"
                        name="arrivingNotificationInterval"
                        onChange={(e) => onArrivingNotificationIntervalChanged(e.target.value)}
                        value={recipient.arrivingNotificationInterval || (settings && settings.arrivingNotificationInterval) || '30'}
                        disabled={recipient.recipientId !== ''}>
                        {['15', '30', '45', '60'].map(item =>
                          <option
                            key={item}
                            value={item}>
                            {`${item} min`}
                          </option>)}
                      </Input>
                    </div>
                  </div>
                </div>
              }
            </div>
            <Button color="primary" type="submit">
              {recipient.id ? "Update" : "Create"}
            </Button>
            &nbsp;
            <Button
              type="button"
              color="secondary"
              onClick={() => showList()}
            >
              Cancel
            </Button>
            <br />
          </form>
          {recipient.id &&
            <DestinationForm {...this.props} />
          }
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({
  mraLicense,
  recipient,
  destination,
  firestore: {
    ordered: { recipients, settings }
  },
  firestore: {
    ordered: { destinations, mraLicenses, users }
  }
}) => ({
  mraLicense,
  recipient: {
    ...recipient,
    id: recipient.id,
  },
  destination,
  recipients,
  companyRecipients: recipients.filter(item => item.type === COMPANY),
  destinations: destinations.filter(item => item.owner === recipient.id && !item.disabled),
  settings: settings && settings.length && settings[0],
  mraLicenses: (mraLicenses && mraLicenses.filter(item => item.owner === recipient.id)) || [],
  customer: users && (users.filter(item => item.recipientId === recipient.id))[0],
  users
});

const mapDispatchToProps = {
  ...recipientsActions,
  ...fetchingActions,
  ...destinationActions,
  ...metrcLicenseActions
};

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  firestoreConnect([
    {
      collection: collections.DESTINATION
    },
    {
      collection: collections.MRA_LICENSE
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CustomerForm);

export default WithLoader(FirestoreConnected);